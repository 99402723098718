'use client'; // Error components must be Client Components

import { useEffect } from 'react';

import * as Sentry from '@sentry/nextjs';

import { ErrorPage } from './system/ErrorPage';

const Error = ({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) => {
  useEffect(() => {
    console.error(error);
    Sentry.captureException(error);
  }, [error]);

  return <ErrorPage />;
};
export default Error;
